import { Area, Currency } from "../../shared/types";
import { DeliveryType } from "../delivery/types";
import { PaymentMethod } from "../payment-method/types";

export enum ProductStatus {
  InCart = "in-cart",
  Ordered = "ordered",
  Redeemed = "redeemed",
  Received = "received",
  NotInStock = "not-in-stock",
  Question = "question",
  Cancelled = "cancelled",
  Processing = "processing",
  Replaced = "replaced",
}

export type Product = {
  id: number;
  title: string;
  url: string;
  images: string[];
  count: string;
  comment: string;
  managerComment: string;
  replacement: string;
  status: ProductStatus;
  price: number;
  delivery: number;
  currency: Currency;
  area: Area;
  createdAt: Date;
  updatedAt: Date;
};

export type Cart = {
  products: Product[];
  summary: CartSummary;
};

export type CartInfo = {
  deliveryTypes: DeliveryType[];
  paymentMethods: PaymentMethod[];
};

export type CartSummary = {
  [key in Currency]?: number;
} & {
  count: number;
};

export type UpdatedCartProduct = {
  product: Product;
  summary: CartSummary;
};

export type PromoApplied = {
  discount: number;
};
