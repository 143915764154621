import React, { useCallback, useEffect, useState } from "react";
import { View } from "../../containers/View";
import { orderService } from "../../api/order";
import { useNavigate, useParams } from "react-router-dom";
import { Order as OrderType } from "../../api/order/types";
import { OrderInfo } from "../../components/Order/Info";
import { getOrderMetadata } from "./helper";
import { OrderItems } from "../../components/Order/Items";
import { Splash } from "../../containers/Splash";
import { useTranslation } from "react-i18next";
import { Button } from "../../lib/Controls/Button";
import { Routes } from "../../config/routes";
import { HelpBlock } from "../../components/Order/HelpBlock";
import { Spin } from "../../lib/Controls/Spin";
import { toast } from "react-hot-toast";
import { OrderCart } from "./OrderCart";

export const Order = React.memo(() => {
  const { orderId } = useParams();
  const [order, setOrder] = useState<OrderType>();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("order");
  const navigate = useNavigate();

  const fetchOrder = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await orderService.getOrder(Number(orderId));

      if (data.success) {
        setOrder(data.data);
      } else {
        toast.error(data.error);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [orderId]);

  const updateProducts = async (productId, newData) => {
    try {
      const { data } = await orderService.updateProduct(
        Number(orderId),
        productId,
        { ...newData, user: order.user },
      );
      if (data.success) {
        toast.success(t("info.updated"));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, [orderId]);

  return (
    <Splash>
      <View>
        <div className="pb-4">
          <div className="flex flex-row justify-between">
            <div>
              <h2 className="text-gray-700 mb-2 text-2xl font-semibold">
                {t("order-title")} {orderId}
              </h2>
            </div>
            <div>
              <Button
                size="xs"
                view="ghost"
                onClick={() => navigate(Routes.Orders)}
              >
                {t("orders-list-back-button")}
              </Button>
            </div>
          </div>
          {isLoading && <Spin size={8} color={"text-gray-700"} />}
          {order && <OrderInfo orderInfo={getOrderMetadata(order)} />}
          <br />
          {order && (
            <OrderItems
              isLoading={isLoading}
              items={order.products}
              onUpdate={updateProducts}
              orderStatus={order.status}
            />
          )}
          <OrderCart onUpdateOrder={setOrder} />
          <br />
          <HelpBlock />
        </div>
      </View>
    </Splash>
  );
});
