import React, { useState } from "react";
import { OrderItemsDataTableProps } from "./types";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { Product } from "../../../../api/cart/types";
import { DataTable } from "../../../../lib/DataTable2";
import { Modal } from "../../../../lib/Modal";
import { Carousel } from "../../../../lib/Carousel";
import { EditableCell } from "./Editable";
import { getProductRowColor } from "../../../../shared/utils";
import { OrderStatus } from "../../../../api/order/types";

export const ItemsDataTable = React.memo(
  ({
    products,
    title,
    isLoading,
    handleUpdate,
    orderStatus,
  }: OrderItemsDataTableProps) => {
    const { t } = useTranslation("cart");
    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);

    const openImagesViewer = (images) => {
      setCurrentImages(images);
      setIsImageModalVisible(true);
    };

    const closeImageViewer = () => {
      setIsImageModalVisible(false);
    };

    const onUpdate = (index, column, value) => {
      handleUpdate(products[index].id, { [column]: value });
    };

    const columnHelper = createColumnHelper<Product>();

    const columns = [
      columnHelper.display({
        header: "#",
        cell: (data) => data.row.index + 1,
      }),
      columnHelper.accessor("images", {
        header: t("products.images"),
        cell: (info) => {
          const images = info.getValue();

          return (
            <div className="relative sm:pl-0 pl-1">
              <img
                className="object-cover h-20 w-20 rounded-sm shadow-gray-200 shadow-sm hover:brightness-90 cursor-pointer"
                src={images[0]}
                alt=""
                onClick={() => openImagesViewer(images)}
              />
              <span className="absolute font-semibold top-1 left-1 rounded-full bg-slate-500 opacity-80 w-3 h-3 text-xxs z-20 text-white flex flex-row justify-center items-center">
                {images.length}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("title", {
        header: t("products.title"),
      }),
      columnHelper.accessor("url", {
        header: t("products.url"),
        cell: (info) => {
          const url = info.renderValue();

          return (
            <a href={url} target={"_blank"} rel="noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                />
              </svg>
            </a>
          );
        },
      }),
      columnHelper.accessor((row) => row.status, {
        id: "status",
        header: t("products.status"),
        cell: (row) => t(`status.${row.getValue()}`),
      }),
      columnHelper.accessor((row) => row.comment, {
        id: "comment",
        header: t("products.comment"),
        cell: (row) => {
          return orderStatus == OrderStatus.Placed ? (
            <EditableCell
              placeholder={t("products.n-a")}
              className={"w-[300px]"}
              type={"textarea"}
              row={row}
              onUpdate={onUpdate}
            />
          ) : (
            <span>{row.getValue()}</span>
          );
        },
      }),
      columnHelper.accessor((row) => row.managerComment, {
        id: "managerComment",
        header: t("products.manager-comment"),
        cell: (info) => (
          <span className="flex w-[300px] break-words whitespace-normal">
            {info.getValue()}
          </span>
        ),
      }),
      columnHelper.accessor((row) => row.replacement, {
        id: "replacement",
        header: t("products.replacement"),
        cell: (row) => {
          return (
            <EditableCell
              placeholder={t("products.n-a")}
              className={"w-[300px]"}
              type={"textarea"}
              row={row}
              onUpdate={onUpdate}
            />
          );
        },
      }),
      columnHelper.accessor((row) => row.count, {
        id: "count",
        header: t("products.count"),
      }),
      columnHelper.accessor(
        (row) => <span className="whitespace-nowrap">{row.price}</span>,
        {
          header: t("products.price"),
          cell: (info) => info.getValue(),
          footer: () => (
            <span className="whitespace-nowrap">{t("products.totals")}</span>
          ),
        },
      ),
      columnHelper.accessor("delivery", {
        id: "delivery",
        header: t("products.delivery"),
        cell: (info) => (
          <span className="whitespace-nowrap">
            {Number(info.getValue()).toFixed(2)}
          </span>
        ),
      }),
      {
        header: t("products.total-cny"),
        cell: (info) => (
          <span className="whitespace-nowrap">
            {Number(
              info.row.original.price * info.row.original.count +
                Number(info.row.original.delivery),
            ).toFixed(2)}
          </span>
        ),
      },
    ];

    const getRowStyles = (row: any) => {
      return {
        background: getProductRowColor(row.original.status),
      };
    };

    return (
      <div className="mb-6">
        <DataTable
          columns={columns}
          data={products}
          isLoading={isLoading}
          title={title}
          noDataTitle={t("empty-cart")}
          getRowStyles={getRowStyles}
        />
        <Modal
          title={t("preview")}
          visible={isImageModalVisible}
          onClose={closeImageViewer}
        >
          <Carousel images={currentImages} />
        </Modal>
      </div>
    );
  },
);
